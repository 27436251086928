'use client';

import * as React from 'react';
import { Locale } from '@/lib/enums/locale.enum';

type EnvironmentValueConfig = {
  dev: string;
  live: string;
};

type TurnstileSiteKeyConfig = {
  sso: EnvironmentValueConfig | null;
};

export interface SiteContextState {
  name: string;
  description: string;
  subdomain: string;
  customDomain: string;
  theme: string;
  themeColor: string;
  locale: Locale;
  default?: boolean;
  css: string;
  consentManagerId: string;
  tagManagerId: string;
  channelId: string;
  turnstileSiteKey: TurnstileSiteKeyConfig | null;
  hotjarId: number | null;
}

export const SiteContext = React.createContext<SiteContextState>({
  name: 'Infopank',
  description: '',
  subdomain: 'infopank',
  customDomain: 'infopank.ee',
  theme: 'infopank',
  themeColor: '#032049',
  locale: Locale.et,
  css: '',
  consentManagerId: '',
  tagManagerId: '',
  channelId: 'infopank',
  turnstileSiteKey: null,
  hotjarId: null,
});

const SiteProvider: React.FunctionComponent<{ children: React.ReactNode; site: SiteContextState }> = (props) => {
  return <SiteContext.Provider value={props.site}>{props.children}</SiteContext.Provider>;
};

SiteProvider.displayName = 'SiteProvider';

export default SiteProvider;
